import React, { useEffect, useMemo, useState } from "react";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useGlobalContext } from "../../../context";
import OwnerAdsFilterSelectBox from "./OwnerAdsFilterSelectBox";
import { AD_FILTER_OPTIONS } from "../../../utils/defaults";
import { blue, grey } from "@mui/material/colors";
import { useParams } from "react-router-dom";
import { fetchOwnerAds } from "../../MainPage/fetchOrSendMainAppData";
import AdCard from "../../MainPage/MainListing/AdCard";
import CustomAvatar from "../../Layout/CustomAvatar";
import { useLocation } from "react-router-dom";
import { getFullImgUrl } from "../../../utils/getFullImgUrl";
import AdToolbar from "../AdToolbar/AdToolbar";
import useRouterTracker from "../../../hooks/useRouterTracker";
import Spinner from "../../../utils/Spinner";
import Footer from "../../Layout/Footer/Footer";
import { getLocalDate } from "../../../utils/humanizeDate";

function getEndings(num) {
  if (num === 1) return "as";
  if (num > 1 && num < 10) return "ai";
  if (num >= 10 && num <= 20) return "ų";
  const lastDigit = num.toString().split("").pop();
  if (lastDigit === "0") return "ų";
  if (lastDigit === "1") return "as";
  if (lastDigit > 1) return "ai";
}

function OwnerAds() {
  const { state, dispatch } = useGlobalContext();
  const params = useParams();
  const ownerId = +params.ownerId;
  const [dateJoined, setDateJoined] = useState(null);
  const location = useLocation();
  const adId = location?.state?.adId;
  // const from = location?.state?.from;
  useRouterTracker("ownerAds");

  const ownerAds = useMemo(() => {
    if (!!state.ownerAdsLoaded) {
      let ads =
        state.ownerAdsFilterValue === "all"
          ? state.ownerAds
          : state.ownerAds?.filter(
              (ad) => ad.properties.ad_type === state.ownerAdsFilterValue
            );
      ads.sort((a, b) => a.properties.price - b.properties.price);
      setDateJoined(ads[0]?.properties?.date_joined);
      return ads;
    }
  }, [state.ownerAds, state.ownerAdsFilterValue, state.ownerAdsLoaded]);

  // const ad = ownerAds?.find((ad) => ad.id === adId);
  // const name = !!adId ? ad?.properties.name : ownerAds[0].properties.name;
  const ad = state?.ad || ownerAds[0];
  const name = ad?.properties?.name;

  const adTypeString =
    !!state.ownerAdsFilterValue && state.ownerAdsFilterValue !== "all"
      ? ` kagegorijoje "${AD_FILTER_OPTIONS.find(
          (option) => option.value === state.ownerAdsFilterValue
        )?.label?.toLowerCase()}"`
      : "";

  useEffect(() => {
    if (
      !state.ownerAds?.length ||
      state?.ownerAds[0]?.properties?.owner !== ownerId
    ) {
      fetchOwnerAds(dispatch, params);
    }
  }, [dispatch, ownerId, params, state.ownerAds]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log("dateJoined", dateJoined);
  // console.log("ownerAds", ownerAds, ownerId, name, adId, state);

  return (
    <>
      <AdToolbar />
      <Box
        sx={{
          backgroundColor: grey[300],
          border: "none",
          pt: 1,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* <Box sx={{ minHeight: "100vh", backgroundColor: grey[300] }}> */}
        {!!state.ownerAdsLoading && (
          <Box sx={{ height: "75vh", width: "100%", display: "flex" }}>
            <Spinner />
          </Box>
        )}
        {!state.ownerAdsLoading &&
          state.ownerAdsLoaded &&
          state?.ownerAds[0]?.properties?.owner === ownerId && (
            <Container maxWidth="md" disableGutters sx={{ border: "none" }}>
              <Grid container sx={{ px: 0.5 }}>
                <Paper
                  elevation={1}
                  sx={{
                    mt: 14,
                    p: 1,
                    position: "relative",
                    backgroundColor: blue[100],
                    border: "solid 1px lightgrey",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {ad?.properties.owner_photo ? (
                    <img
                      src={getFullImgUrl(ad.properties.owner_photo)}
                      alt="owner"
                      style={{
                        width: "65px",
                        borderRadius: ad.properties.original_id
                          ? "none"
                          : "50%",
                      }}
                    />
                  ) : (
                    <CustomAvatar
                      userId={ownerId}
                      name={name}
                      styleProps={{
                        // margin: "4px",
                        width: "65px",
                        height: "65px",
                        border: "solid 1px grey",
                      }}
                    />
                  )}

                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ border: "none" }}
                  >
                    <Grid
                      container
                      item
                      // xs={12}
                      // md={7}
                      alignItems="center"
                      justifyContent="left"
                      sx={{ border: "none", display: "flex" }}
                    >
                      <Typography variant="body1" sx={{ m: 1 }}>
                        {name} |{" "}
                        <Typography variant="body2" sx={{ display: "inline" }}>
                          ID: {ownerId}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Typography
                      variant="caption"
                      color="grey"
                      sx={{ mt: -1, ml: 1 }}
                    >
                      Narys nuo {getLocalDate(dateJoined)}
                    </Typography>
                    <Grid
                      container
                      item
                      // xs={12}
                      // md={5}
                      justifyContent="space-between"
                      sx={{ border: "none" }}
                    >
                      <Typography variant={"body2"} sx={{ m: 1 }}>
                        {ownerAds?.length
                          ? `${ownerAds.length} skelbim${getEndings(
                              ownerAds.length
                            )}`
                          : `Vartotojas nėra įkėlęs skelbimų${adTypeString}`}
                      </Typography>
                      <OwnerAdsFilterSelectBox />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {!!ownerAds?.length && (
                <Grid container justifyContent="center" sx={{ px: 0.5 }}>
                  {ownerAds.map((ad) => (
                    <Grid
                      container
                      item
                      key={ad.id}
                      sx={{ mt: 2, width: "100%" }}
                    >
                      <AdCard ad={ad} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Container>
          )}
        <Footer />
      </Box>
    </>
  );
}

export default OwnerAds;
