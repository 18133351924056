import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { useHistory } from "react-router-dom";

import { useGlobalContext } from "../../../context";
import reloadAds from "../../../utils/getUrlFromParams";
import { Box } from "@mui/material";
import useParamsFromUrl from "../hooks/useParamsFromUrl";
import AdsPerPageSelector from "./AdsPerPageSelector";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

export default function PageNavigation() {
  const { state, dispatch } = useGlobalContext();
  const history = useHistory();
  const totalPages = state.totalPages;
  const params = useParamsFromUrl();
  const [showPagination, setShowPagination] = useState(false);
  const { isMobile } = useCustomMediaQuery();

  // add time sleep
  useEffect(() => {
    setTimeout(() => {
      setShowPagination(true);
    }, 300);
  }, []);

  function handlePageChange(e, page) {
    let newParams = { ...params, page };
    reloadAds(newParams, dispatch, history);
  }

  return (
    showPagination && (
      <Box
        sx={{
          display: "flex",
          mt: 5,
          px: 0.5,
          flexDirection: {
            xs: "column",
            md: state.showMap ? "column" : "row",
          },
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        gap={2}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            border: "none",
          }}
        >
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              color="primary"
              variant="outlined"
              shape="rounded"
              page={+params?.page || 1}
              onChange={handlePageChange}
              siblingCount={isMobile ? 0 : 1}
              boundaryCount={1}
            />
          )}
        </Box>
        {totalPages > 0 && (
          <Box sx={{ flexGrow: 0, border: "none" }}>
            <AdsPerPageSelector />
          </Box>
        )}
      </Box>
    )
  );
}
