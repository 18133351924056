import React from "react";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/system";
import AdCardTitleInfo from "../AdCardInfo/AdCardTitleInfo";
import BasicAdCardInfo from "../AdCardInfo/BasicAdCardInfo";
import { getFullImgUrl } from "../../../../utils/getFullImgUrl";
import { getDefaultPhotoUrl } from "../../../../utils/getDefaultPhotoUrl";
import AdStatusText from "./AdStatusText";

export default function AdCardFormatSmallImg({ ad }) {
  return (
    <Box sx={{ display: "flex", alignItems: "stretch" }}>
      <Box sx={{ width: "100%", p: 0 }}>
        <Grid
          container
          sx={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Grid container sx={{ display: "flex", flexDirection: "row" }}>
            <RouterLink
              to={`/skelbimas/${ad.properties.slug}/${ad.id}`}
              sx={{ textDecoration: "none" }}
            >
              <Box sx={{ border: "none", position: "relative" }}>
                <AdStatusText ad={ad} sx={{ width: "100%" }} />
                <Box
                  component="img"
                  src={
                    getFullImgUrl(ad.properties.photos[0]?.photo_thumbnail) ||
                    getDefaultPhotoUrl()
                  }
                  sx={{ height: "50px", width: "auto" }}
                />
              </Box>
            </RouterLink>
            <AdCardTitleInfo ad={ad} />
          </Grid>

          <Grid container sx={{ px: 1, my: 1, flexGrow: 1 }}>
            <BasicAdCardInfo ad={ad} />
          </Grid>

          {(ad.properties.is_exact || !!ad.properties.original_id) &&
            ad.properties?.preference_score > 0 && (
              <LinearProgress
                variant="determinate"
                value={(+ad.properties?.preference_score * 100) / 5}
                color="success"
                sx={{ opacity: 0.8, height: "3px" }}
              />
            )}
        </Grid>
      </Box>
    </Box>
  );
}
