import {
  Grid,
  Typography,
  Link,
  Paper,
  Divider,
  Stack,
  Button,
  Avatar,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
// import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { NavLink } from "react-router-dom";
import { useGlobalContext } from "../../context";
import ChatIcon from "@mui/icons-material/Chat";
import slugify from "slugify";
import { getFullImgUrl } from "../../utils/getFullImgUrl";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { grey } from "@mui/material/colors";

function ContactInfo({ ad, handleWriteMessage }) {
  const { userState } = useGlobalContext();

  return (
    <Paper sx={{ p: 1, width: "100%" }}>
      <Stack direction="row" sx={{ flexDirection: "row" }}>
        <Stack
          direction="column"
          sx={{
            justifyContent: "space-between",
            display: "flex",
            border: "none",
            py: 0.5,
          }}
        >
          {getFullImgUrl(ad.properties.owner_photo) ? (
            <Box
              component="img"
              src={getFullImgUrl(ad.properties.owner_photo)}
              alt="owner"
              sx={{
                width: "auto",
                maxWidth: "85px",
                borderRadius: ad.properties.original_id ? "none" : "50%",
              }}
            />
          ) : (
            <Avatar
              sx={{
                width: "85px",
                height: "85px",
                // color: grey[500],
                bgcolor: grey[300],
              }}
            />
          )}

          {true && (
            <Link
              component={NavLink}
              to={{
                pathname: `/vartotojas/${ad.properties.owner}/${slugify(
                  ad.properties.name,
                  {
                    lower: true,
                  }
                )}`,
                state: { adId: ad.id, type: "ownerAds" },
              }}
              underline="hover"
              sx={{ display: "flex", justifyContent: "center", mt: 0.5 }}
            >
              <Typography variant="caption" color="text.primary">
                Visi skelbimai
              </Typography>
            </Link>
          )}
        </Stack>

        {/* <Grid container sx={{ border: "groove" }}>
        <Grid
          container
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "groove",
          }}
        >
          {getPhotoUrl(ad.properties.owner_photo) ? (
            <img
              src={getPhotoUrl(ad.properties.owner_photo)}
              alt="owner"
              style={{
                width: "100%",
                // height: "120px",
                // maxHeight: "100px",
                // width: "auto",
                // maxWidth: "100%",
                borderRadius: "50%",
              }}
            />
          ) : (
            <BsPerson fontSize="70px" color="gray" />
          )}

          <Link
            component={NavLink}
            to={"#"}
            underline="hover"
            sx={{ border: "groove" }}
          >
            <Typography variant="caption" color="text.primary" sx={{ ml: 1 }}>
              Visi skelbimai
            </Typography>
          </Link>
        </Grid> */}

        <Stack sx={{ border: "none", flexGrow: 1 }}>
          <Grid
            container
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              border: "none",
            }}
          >
            <Grid container sx={{ border: "none" }}>
              <Grid
                container
                item
                xs={12}
                sx={{
                  border: "none",
                  display: "flex",
                  justifyContent: "center",
                  mt: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  // fontWeight={500}
                  sx={{ border: "none" }}
                >
                  {ad.properties.name}
                </Typography>
              </Grid>
            </Grid>

            <Divider variant="middle" />

            <Grid
              container
              sx={{ border: "none", display: "flex", justifyContent: "center" }}
            >
              <Typography variant="caption" sx={{ mt: 0.5, border: "none" }}>
                {ad.properties.owner_type}
              </Typography>
            </Grid>
            {/* {true && (
              <Grid
                container
                sx={{
                  border: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link component={NavLink} to={"#"} underline="hover">
                  <Typography
                    variant="caption"
                    color="text.primary"
                    sx={{ ml: 1 }}
                  >
                    (Visi skelbimai)
                  </Typography>
                </Link>
              </Grid>
            )} */}

            <Stack direction="column" sx={{ border: "none", mt: 1, py: 0.5 }}>
              {!!ad.properties.phone && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PhoneIphoneIcon sx={{ color: "gray", fontSize: "17px" }} />

                  <Typography variant="subtitle2" sx={{ ml: 1 }}>
                    {formatPhoneNumberIntl(ad.properties.phone)}
                  </Typography>
                </Box>
              )}

              {!ad.properties.email && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="text"
                    startIcon={
                      <ChatIcon
                        sx={{
                          color:
                            userState?.userId === ad.properties.owner ||
                            userState?.currentUser?.suspended
                              ? "lightgrey"
                              : "gray",
                        }}
                      />
                    }
                    color="inherit"
                    sx={{ mt: 1, mb: -0.5 }}
                    onClick={handleWriteMessage}
                    disabled={
                      userState?.userId === ad.properties.owner ||
                      userState?.currentUser?.suspended
                    }
                  >
                    <Typography variant="caption">Rašyti žinutę</Typography>
                  </Button>
                </Box>
              )}

              {!!ad.properties.email && (
                <Box
                  sx={{
                    display: "flex",
                    mt: 1,
                    // ml: 2,
                    justifyContent: "center",
                    // alignItems: "center",
                    border: "none",
                  }}
                >
                  <MailOutlineIcon fontSize="small" sx={{ color: "gray" }} />
                  <Typography variant="caption" noWrap sx={{ ml: 1 }}>
                    {ad.properties.email}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Grid>
        </Stack>
      </Stack>

      {["Tarpininkas", "Vystytojo atstovas"].includes(
        ad.properties.owner_type
      ) &&
        (ad.properties.company_name || ad.properties.company_logo) && (
          <>
            <Divider />
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-evenly",
                alignItems: "center",
                mt: 1,
                // textAlign: "center",
              }}
            >
              {!!ad?.properties?.company_logo && (
                <Box
                  component="img"
                  src={getFullImgUrl(ad.properties.company_logo)}
                  alt="company logo"
                  sx={{ maxHeight: "50px", maxWidth: "100px", mr: 1 }}
                />
              )}
              <Typography variant="body2">
                {ad.properties.company_name}
              </Typography>
            </Stack>
          </>
        )}
    </Paper>
  );
}

export default ContactInfo;
