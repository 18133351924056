import React, { useEffect, useState } from "react";
import { Tab, Tabs, Box, Paper } from "@mui/material";
import EnquiryForm from "./AccordionEnquiry/EnquiryForm";
import Notes from "./AccordionNotes/Notes";
import { useGlobalContext } from "../../context";
import Spinner from "../../utils/Spinner";

export default function MessageNoteTab({ ad, writeMessage, setWriteMessage }) {
  const [value, setValue] = useState(1);
  const { state, userState, dispatchUser, dashboardState } = useGlobalContext();

  const handleChange = (event, newValue) => {
    if (!userState.isAuthenticated) {
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { openLoginDialog: true },
      });
      return;
    }
    setValue(newValue);
  };

  useEffect(() => {
    setValue(1);
  }, [ad.id]);

  useEffect(() => {
    if (writeMessage) {
      setValue(0);
      setWriteMessage(false);
    }
  }, [setWriteMessage, writeMessage]);

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        mt: 1,
        mb: 0.5,
        // pb: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider", border: "none" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ width: "100%" }}
        >
          <Tab
            label="Žinutės"
            disabled={
              !!ad.properties.email ||
              ad.properties.owner === userState.userId ||
              !!userState?.currentUser?.suspended
            }
            sx={{ width: "50%" }}
          />
          <Tab label="Užrašinė" sx={{ width: "50%" }} />
        </Tabs>
      </Box>
      {value === 0 && (
        <Box sx={{ flexGrow: 1 }}>
          {dashboardState.enquiriesLoaded &&
          dashboardState.myEnquiryAdsLoaded ? (
            <EnquiryForm />
          ) : (
            <Spinner />
          )}
        </Box>
      )}
      {value === 1 && (
        <Box sx={{ pt: 2, px: 1, height: "85%", border: "none" }}>
          {!!state.adsLoading ? <Spinner /> : <Notes ad={ad} />}
        </Box>
      )}
    </Paper>
  );
}
