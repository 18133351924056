import React, { useState } from "react";
import DOMPurify from "dompurify";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useAdType from "./BasicAdInfo/useAdType";

const AccordionDescription = ({ ad }) => {
  const [expanded, setExpanded] = useState(true);
  const { isApartmentComplex } = useAdType(ad);

  const sanitizedHtml = DOMPurify.sanitize(ad.properties.description, {
    RETURN_DOM: true,
  });

  return (
    <Accordion
      expanded={expanded}
      onChange={(event, expanded) => {
        setExpanded(expanded);
      }}
      sx={{ width: "100%" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="button" color={expanded ? "primary" : "default"}>
          {!isApartmentComplex ? "Papildomas aprašymas" : "Apie projektą"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "block" }}>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: sanitizedHtml.outerHTML }}
          style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}
        />
        {/* {DOMPurify.sanitize(ad.properties.description)} */}
        {/* {ad.properties.description} */}
        {/* </Typography> */}
      </AccordionDetails>
    </Accordion>
    // </div>
  );
};

export default AccordionDescription;
