import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box } from "@mui/system";
import { red } from "@mui/material/colors";
import useAdType from "../../../IndividualAd/BasicAdInfo/useAdType";
import {
  getAddress,
  getPrice,
  getPriceSqm,
  getPriceRange,
  getPriceSqmRange,
} from "../../../IndividualAd/BasicAdInfo/utils";
import StarIcon from "@mui/icons-material/Star";
import useHandleLikeClick from "../../../../hooks/useHandleLikeClick";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";

export default function AdCardTitleInfo({ ad }) {
  const { isPremises, isApartmentComplex, isRent, isLand } = useAdType(ad);
  const { isMobile } = useCustomMediaQuery();
  const price = getPrice(ad, isRent);
  const priceRange = getPriceRange(ad, isRent);
  const priceSqm = getPriceSqm(ad);
  const priceSqmRange = getPriceSqmRange(ad, isRent);
  const address = getAddress(ad);

  const { userLikes, handleLikeClick } = useHandleLikeClick(ad.id);

  const projectName =
    ad.properties?.project_name?.replaceAll("'", "")?.replaceAll('"', "") ||
    "Naujas projektas";

  return (
    <Box sx={{ flexGrow: 1, border: "none" }}>
      <Grid
        container
        justifyContent="space-between"
        sx={{ pt: 0.5, pr: 0.5, border: "none" }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body2"
            color="primary"
            fontSize={isMobile ? 16 : 18}
            sx={{ mx: 1, border: "none", fontWeight: "bold" }}
          >
            {isApartmentComplex ? projectName : isPremises ? priceRange : price}
          </Typography>
          {!isApartmentComplex && (
            <Typography variant="body2" fontSize={12} sx={{ border: "none" }}>
              {!isPremises ? priceSqm : priceSqmRange} {isLand ? "€/a" : "€/m²"}
            </Typography>
          )}
        </Box>
        <Box sx={{ border: "none", display: "flex", alignItems: "center" }}>
          {(ad.properties.is_exact || ad.properties.original_id) &&
            ad.properties?.preference_score > 0 && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2" sx={{ mt: 0.2 }}>
                  {ad.properties.preference_score.toFixed(1)}
                </Typography>
                <StarIcon color="warning" sx={{ fontSize: 18, mr: 0.2 }} />
              </Box>
            )}

          {!["expired", "deleted"].includes(ad.properties.status) && (
            <IconButton onClick={() => handleLikeClick(ad.id)} size="small">
              <FavoriteIcon
                fontSize="small"
                sx={{
                  color: userLikes ? red[500] : "default",
                  opacity: userLikes ? 1 : 0.5,
                }}
              />
            </IconButton>
          )}
        </Box>
      </Grid>
      <Grid container justifyContent="space-between" sx={{ border: "none" }}>
        <Typography variant="body2" fontSize={12} sx={{ mt: -0.5, ml: 1 }}>
          {address}
        </Typography>
      </Grid>
    </Box>
  );
}
